import React from "react"
import aquaStyle from './plazaAquaPark.module.css'
import rooms from '../../../assets/hotels/Rectangle 6.png'
import kidsArea from '../../../assets/hotels/Rectangle 13.png'
import Meetings from '../../../assets/hotels/Rectangle 13(2).png'
import RomanticDining from '../../../assets/hotels/Rectangle 13(1).png'
import person from '../../../assets/Main/Vector2.png'

import Navbar from "../../navbar/navbar"
import { Footer } from "../../footer/footer"
import { Link } from "react-router-dom"
import { FamilyRoom } from "../../sections/familyRoom/familyRoom"
import { StandardRoom } from "../../sections/standerdRoom/standerdRoom"

export const PlazaAquaPark = ()=>{
   return<>
      <section>
      <div className={`vh-50 text-center d-flex justify-content-center align-items-center ${aquaStyle.bgImage}`}>
<div className='w-75'>
   <h1 className='text-white py-5 fs-7'>Regency Plaza Aqua Park</h1>
   <div className={`py-5 rounded-5 layer`}>

      <h4>Book a Room</h4>
      <p>Discover the best space for you</p>
      <div className='d-flex justify-content-center align-items-center'>
         <div className='col-md-3'>
            <h6>Date</h6>
            <p>check available</p>
         </div>
         <div className='col-md-3'>
            <h6 className='text-start ms-5'>Person</h6>
            <div className='d-flex justify-content-center align-items-center'>
            <p className='mb-0'>Adults</p>
            <select class="form-select bg-transparent border-0 w-25 ms-3" aria-label="Default select example">
  <option value="1" selected>1</option>
  <option value="2">2</option>
  <option value="3">3</option>
</select>
            </div>

         </div>
         <div className='col-md-3 d-flex justify-content-center align-items-center mt-4'>
         <p  className='mb-0'>Children</p>
         <select class="form-select bg-transparent border-0 w-25 ms-3" aria-label="Default select example">
  <option value="1" selected>1</option>
  <option value="2">2</option>
  <option value="3">3</option>
</select>
         </div>
         <div className='col-md-4'>
            <button className='btn bgPrimaryColor text-white fs-5 text-uppercase'>book now</button>
         </div>
      </div>
      </div>
</div>
      </div>
   </section>
   <section className="mt-5">
   <div className=" text-center p-3">
          <h2 className="textMain">Why Us?</h2>
        </div>
        <div className={`${aquaStyle.bgWhyUs}`}></div>
        <div className={`text-center d-flex justify-content-center align-items-center p-5 ${aquaStyle.bgWhyUsContent}`}>
        <div className="container text-white">
         <div className="row justify-content-center align-items-center pb-5">
               <div className="col-md-6">
   <div className="d-flex justify-content-center align-items-center">
<div className="col-2 ">
<h2 className="h1">1</h2>
</div>
<div className="col-10 text-start">
<h5>Our hotel stands out for its prime and central location</h5>
<p>offering our guests easy access to key attractions, vibrant nightlife, and renowned dining options, ensuring a memorable stay.</p>
</div>
   </div>
               </div>
               <div className="col-md-6">
   <div className="d-flex justify-content-center align-items-center">
<div className="col-2 ">
<h2 className="h1">3</h2>
</div>
<div className="col-10 text-start">
<h5>Our hotel stands out for its prime and central location</h5>
<p>offering our guests easy access to key attractions, vibrant nightlife, and renowned dining options, ensuring a memorable stay.</p>
</div>
   </div>
               </div>
               <div className="col-md-6 pt-4">
   <div className="d-flex justify-content-center align-items-center">
<div className="col-2 ">
<h2 className="h1">2</h2>
</div>
<div className="col-10 text-start">
<h5>Our hotel stands out for its prime and central location</h5>
<p>offering our guests easy access to key attractions, vibrant nightlife, and renowned dining options, ensuring a memorable stay.</p>
</div>
   </div>
               </div>
               <div className="col-md-6 pt-4">
   <div className="d-flex justify-content-center align-items-center">
<div className="col-2 ">
<h2 className="h1">4</h2>
</div>
<div className="col-10 text-start">
<h5>Our hotel stands out for its prime and central location</h5>
<p>offering our guests easy access to key attractions, vibrant nightlife, and renowned dining options, ensuring a memorable stay.</p>
</div>
   </div>
               </div>
            </div>
   </div>        
   </div>
   </section>
   <section className="bg-light py-5">
      <div className=" container m-auto row py-5 text-white">
<div className="col-md-4">
<div className="position-relative">
<img src={rooms} className="w-100" alt="" />
<p className="position-absolute top-90 start-50 translate-middle-x fs-4 fw-semibold">Rooms</p>
</div>

</div>
<div className="col-md-8 row">
   <div className={`col-md-6 d-flex justify-content-center align-items-end ${aquaStyle.bgImage1}`}>
      <p className="fs-4 fw-semibold pb-5">Dining</p>
      </div>
      <div className={`col-md-6 d-flex justify-content-center align-items-end ${aquaStyle.bgImage2}`}>
      <p className="fs-4 fw-semibold pb-5">Conferences & Meetings</p>
      </div>
   <div className={`col-md-6 d-flex justify-content-center align-items-end ${aquaStyle.bgImage3}`}>
      <p className="fs-4 fw-semibold pb-5">Gym & Spa</p>
   </div>
   <div className={`col-md-6 d-flex justify-content-center align-items-end ${aquaStyle.bgImage4}`}>
      <p className="fs-4 fw-semibold pb-5">Shopping area</p>

   </div>
</div>
      </div>
   </section>
   <section className="container my-5">
      <div className="">
         <h4 className="textMain">Special Offers</h4>
         <div className=" d-flex">
            <div className="col-10">
            <h2>Best offer of the month</h2>
            </div>
            <div className="col-2">
            <Link className="fs-5 fw-semibold text-decoration-none textMain">View all</Link>
            </div>
         </div>
         <div className="col-6">
         <p>Experience Fantastic Benefits and Obtain Better Rates When You Make a Direct Booking on Our Official Website</p>
         </div>
      </div>
      <div className="row py-4">
<div className="col-md-4">
   <div className="shadow rounded-5 p-3">
      <img src={kidsArea} className="w-100" alt="" />
      <span>Room</span>
      <div className="d-flex justify-content-between">
      <h6>kids area</h6>
      <div className="d-flex">
         <div>
<img src={person} className="w-100" alt="" />
         </div>
         <div>
<p className="pt-1 ps-2">2</p>

         </div>
      </div>
      </div>
      <p>Indulge in a Memorable One-Time Romantic Dinner for Two</p>
      <div className="d-flex justify-content-center">
      <h3>$699</h3>
      <span className="pt-2">/night</span>
      </div>
   </div>
</div>
<div className="col-md-4">
   <div className="shadow rounded-5 p-3">
      <img src={Meetings} className="w-100" alt="" />
      <span>Room</span>
      <div className="d-flex justify-content-between">
      <h6>Meetings</h6>
      <div className="d-flex">
         <div>
<img src={person} className="w-100" alt="" />
         </div>
         <div>
<p className="pt-1 ps-2">2</p>
         </div>
      </div>
      </div>
      <p>Experience an Exclusively Private Environment to Boost Your Productivity</p>
      <div className="d-flex justify-content-center">
      <h3>$699</h3>
      <span className="pt-2">/night</span>
      </div>
   </div>
</div>
<div className="col-md-4">
   <div className="shadow rounded-5 p-3">
      <img src={RomanticDining} className="w-100" alt="" />
      <span>Room</span>
      <div className="d-flex justify-content-between">
      <h6>Romantic Dining</h6>
      <div className="d-flex">
         <div>
<img src={person} className="w-100" alt="" />
         </div>
         <div>
<p className="pt-1 ps-2">2</p>

         </div>
      </div>
      </div>
      <p>Indulge in a Memorable One-Time Romantic Dinner for Two</p>
      <div className="d-flex justify-content-center">
      <h3>$699</h3>
      <span className="pt-2">/night</span>
      </div>
   </div>
</div>
      </div>
   </section>
 <FamilyRoom/>
 <StandardRoom/>
   
   </>
}