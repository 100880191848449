import axios from "axios";

 export const BASE_URL = 'https://apibooking.violetdecoreg.com/api';
 
 const _axios = axios.create({baseURL: BASE_URL})

 // Function to set the token
export const setAuthToken = (token) => {
  if (token) {
    // Apply the token to every request
    _axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Remove the token from the headers if there's no token
    delete _axios.defaults.headers.common['Authorization'];
  }
};

 export const signIn = async(data)=>{
  const response = await _axios.post('/Auth/SignIn', data);
  const token = response.data; 
  console.log(token);
  setAuthToken(token);
  return response;
}

export const signUp = async(data)=>{
  const response = await _axios.post('Auth/SignUp', data);
  return response;
}

export const GetAllRoomTypes = async()=>{
  const response = await _axios.get('/RoomTypes/GetAllRoomTypes');
  return response;
}

 
