import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signIn,signUp,GetAllRoomTypes } from "./api";
import { useNavigate } from "react-router-dom";

export function useLogin(){
  const navigate = useNavigate()
  const queryClient = useQueryClient()
   return useMutation({
       mutationFn: (data)=> signIn(data),
       onMutate: ()=>{
         console.log('mutate');
       },
       onError: ()=>{
         console.log('error');
       },
       onSuccess: (response)=>{
         console.log('success',response);
       },
       onSettled:async (response,error)=>{
         console.log('settled');
        if (error) {
          console.log(error);
        }else{
          console.log(response);
          await queryClient.invalidateQueries({queryKey:['signIn']})
          navigate('/home')
        }
       },
   })
}

export function useSignUp(){
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  return useMutation({
      mutationFn:(data)=> signUp(data),

      onMutate: ()=>{
        console.log('mutate');
      },
      onError: ()=>{
        console.log('error');
      },
      onSuccess: (response)=>{
        console.log('success',response);
      },
      onSettled:async (_,error)=>{
        console.log('settled');
       if (error) {
         console.log(error);
       }else{
        console.log('dsdsds');
         await queryClient.invalidateQueries({queryKey:['signUp']})
         navigate('/login')
       }
      },
  })
}

// export function GetRoomTypes(){
//   return useMutation({
//       mutationFn:()=> GetAllRoomTypes(),

//       onMutate: ()=>{
//         console.log('mutate');
//       },
//       onError: ()=>{
//         console.log('error');
//       },
//       onSuccess: (response)=>{
//         console.log('success',response);
//       },
//       onSettled:async (_,error)=>{
//         console.log('settled');
//        if (error) {
//          console.log(error);
//        }else{
//         console.log('dsdsds');
//         //  await queryClient.invalidateQueries({queryKey:['signUp']})
//        }
//       },
//   })
// }