import aboutUs from '../../assets/contact/bg style.png'
import aboutUs2 from '../../assets/contact/Ellipse 626.png'
import Navbar from '../navbar/navbar'
import goals from '../../assets/contact/Group 194 (1).png'
import romantic from '../../assets/contact/Illustration.png'
import goals2 from '../../assets/contact/image 919.png'
import aboutStyle from './about.module.css'
import ReviewsSlider from '../sections/reviewsSlider/reviewsSlider'
import { Footer } from '../footer/footer'

export default function About(){
   return<>
   <section className={` ${aboutStyle.bannerBg}`}>
      <div className={`d-flex justify-content-center align-items-center ${aboutStyle.bannerBgColor}`}>
<div>
   <h1 className='BigshotOneFont text-white fs-7'>About Us</h1>
</div>
      </div>
   </section>
   <section className="container my-5 pb-5">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-6">
         <div className='px-5'>
<h6 className='textPrimaryColor'>About us</h6>
<h3>We promise you an unforgettable stay, combining elegance with exceptional service.</h3>
<p>Delight in dining and drinks at our private restaurant and bar, savoring the quality of our food and  beverage offerings. Our professionally trained waiters provide excep�onal service as you enjoy a wide  range of cuisines and live cooking experiences, ensuring your taste buds are always delighted with  something unique</p>
        </div>
         </div>
        <div className="col-md-6  order-first order-md-3">
<div className=''>
<img src={aboutUs} className='w-60 position-absolute' alt="" />
<img src={aboutUs2} className='w-75 position-relative top-20 start-20' alt="" />
</div>
        </div>
      </div>
      </section>
      <section className={` ${aboutStyle.wanderlustBg}`}>
      <div className={`d-flex justify-content-center align-items-center h-100 ${aboutStyle.overLay}`}>
<div className='pt-5'>
   <h1 className='BigshotOneFont text-white fs-7'>Wanderlust</h1>
</div>
      </div>
   </section>
   <section className="container my-5">
      <div className="row align-items-center justify-content-center position-relative ">
        <div className="col-md-6">
<div className=''>
<img src={goals} className='w-100' alt="" />
</div>
        </div>
        <div className="col-md-6  ">
         <div className='position-absolute top-0 end-0'>
         <img src={goals2} className='w-100' alt="" />
         </div>
         <div className='px-5'>
<h2 className='BigshotOneFont'>Our Goals & Plans</h2>
<p>Romance Regency Club is a VIP club designed for individuals aged 16 and above. Our club offers a  luxurious experience with all rooms equipped with king-size beds and separated into categories such as  Garden, Pool view, Swim up rooms, and Suites. Each room provides a beau�ful view and a comfortable  se�ng for a roman�c vaca�on. The Ultra All-Inclusive package offers a wide range of dining and drinking op�ons. Enjoy the privacy of  our private restaurant and bar, where professionally trained waiters will serve you high-quality food and  beverages. We have a variety of restaurants with different cuisines to sa�sfy every taste preference,  including live cooking sta�ons</p>       
 </div>
         </div>
      </div>
      </section>
      <section className="container my-5">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-6 order-first order-md-3">
<div className=''>
<img src={romantic} className='w-100' alt="" />
</div>
        </div>
        <div className="col-md-6">
         <div className='px-5'>
<h2 className='BigshotOneFont h1'>Our Romantic Tropical Destinations</h2>
<p>The Ultra All-Inclusive package offers a wide range of dining and drinking op�ons. Enjoy the privacy of  our private restaurant and bar, where professionally trained waiters will serve you high-quality food and  beverages. We have a variety of restaurants with different cuisines to sa�sfy every taste preference,  including live cooking sta�ons</p>
 </div>
         </div>
      </div>
      </section>
      <section className={`my-5 ${aboutStyle.reviews}`}>
      <div className={`d-flex justify-content-center align-items-center h-100 ${aboutStyle.overLay}`}>
<div className='pt-5 text-center'>
   <ReviewsSlider/>
</div>
      </div>
   </section>
   </>
}