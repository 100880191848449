import royalRegencyStyle from './royalRegency.module.css'
import img from '../../../assets/hotels/image 1 (1).png'
import bgHotel from '../../../assets/hotels/Group 194.png'
import Navbar from '../../navbar/navbar'
import { Categories } from '../../sections/categories/categories'
import { RecentlyViewed } from '../../sections/recentlyViewed/RecentlyViewed'
import { AllRooms } from '../../sections/allRooms/allRooms'
import { Offers } from '../../sections/offers/offers'
import { Footer } from '../../footer/footer'

export const RoyalRegency =()=>{
   return<>
   <section>
      <div className={`vh-100 text-center d-flex justify-content-center align-items-center ${royalRegencyStyle.bgImage}`}>
<div className='w-75'>
   <h1 className='text-white py-5 fs-7'>Royal Regency Club</h1>
   <div className={`py-5 rounded-5 layer`}>

      <h4>Book a Room</h4>
      <p>Discover the best space for you</p>
      <div className='d-flex justify-content-center align-items-center'>
         <div className='col-md-3'>
            <h6>Date</h6>
            <p>check available</p>
         </div>
         <div className='col-md-3'>
            <h6 className='text-start ms-5'>Person</h6>
            <div className='d-flex justify-content-center align-items-center'>
            <p className='mb-0'>Adults</p>
            <select class="form-select bg-transparent border-0 w-25 ms-3" aria-label="Default select example">
  <option value="1" selected>1</option>
  <option value="2">2</option>
  <option value="3">3</option>
</select>
            </div>

         </div>
         <div className='col-md-3 d-flex justify-content-center align-items-center mt-4'>
         <p  className='mb-0'>Children</p>
         <select class="form-select bg-transparent border-0 w-25 ms-3" aria-label="Default select example">
  <option value="1" selected>1</option>
  <option value="2">2</option>
  <option value="3">3</option>
</select>
         </div>
         <div className='col-md-4'>
            <button className='btn bgPrimaryColor text-white fs-5 text-uppercase'>book now</button>
         </div>
      </div>
      </div>
</div>
      </div>
   </section>
   <Categories/>
   <AllRooms/>
   <RecentlyViewed/>
   <Offers/>
<section className={`${royalRegencyStyle.bgHotelRoom} mb-5 `}>
   <div className='d-flex justify-content-center align-items-center'>
<div className='col-md-6'>
   <img src={bgHotel} alt="bgHotel" srcset="" />
</div>
<div className='col-md-4'>
   <div className='item text-white'>
      <h2 className='BigshotOneFont'>Royal regency CLub</h2>
   </div>
   <div className='content text-white'>
<div className='mb-4'>
<span className='border-end px-3 me-3'>Sharm</span>
<span>300 reviews</span>
</div>
<div className='pe-5'>
<p>"Welcome to our grand unveiling! We are thrilled to open the doors of our new hotel and invite you to embark on a journey of luxury, comfort, and exceptional hospitality."</p>
</div>
<div className='border-end w-25'>
<button className='btn bgPrimaryColor rounded-5 text-uppercase'>book now</button>
</div>
   </div>
</div>
   </div>
</section>
   </>
}