import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slider1 from '../../../assets/Login/Group 4.png'

function SignUpSlider() {

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    arrows:false,
    autoplay:true
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img src={slider1 } className="w-100" />
        </div>
        <div>
          <img src={slider1}  className="w-100" />
        </div>
        <div>
          <img src={slider1 }  className="w-100" />
        </div>
      </Slider>
    </div>
  );
}

export default SignUpSlider;
