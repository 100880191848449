import React from "react";
import Slider from "react-slick";
import category1 from '../../../assets/categories/image 18.png'
import category2 from '../../../assets/categories/image 18 (1).png'
import category3 from '../../../assets/categories/image 18 (2).png'
import category4 from '../../../assets/categories/image 18 (3).png'
import categoriesStyle from './categories.module.css'


export const Categories = ()=>{
   var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      arrows: false, // Disable arrows
      autoplay: true, // Enable autoplay
      autoplaySpeed: 2000, // Set autoplay speed to 2 seconds
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
   return<>
   <section className="container my-5">
   <div className="py-3">
      <h2>Top Categories</h2>
   </div>
   <div className="slider-container m-auto">
      <Slider {...settings}>
        <div className={`pe-4 ${categoriesStyle.category}`}>
         <img className="w-100" src={category1} alt="" srcset="" />
          <h4 className={`text-white ${categoriesStyle.categoryName}`}>Rooms</h4>
        </div>
        <div className={`pe-4 ${categoriesStyle.category}`}>
         <img className="w-100" src={category2} alt="" srcset="" />
          <h4 className={`text-white ${categoriesStyle.categoryName}`}>Rooms</h4>
        </div>
        <div className={`pe-4 ${categoriesStyle.category}`}>
         <img className="w-100" src={category3} alt="" srcset="" />
          <h4 className={`text-white ${categoriesStyle.categoryName}`}>Rooms</h4>
        </div>
        <div className={`pe-4 ${categoriesStyle.category}`}>
         <img className="w-100" src={category4} alt="" srcset="" />
          <h4 className={`text-white ${categoriesStyle.categoryName}`}>Rooms</h4>
        </div>
        <div className={`pe-4 ${categoriesStyle.category}`}>
         <img className="w-100" src={category1} alt="" srcset="" />
          <h4 className={`text-white ${categoriesStyle.categoryName}`}>Rooms</h4>
        </div>
        <div className={`pe-4 ${categoriesStyle.category}`}>
         <img className="w-100" src={category2} alt="" srcset="" />
          <h4 className={`text-white ${categoriesStyle.categoryName}`}>Rooms</h4>
        </div>
      </Slider>
    </div>
   </section>
   </>
}