import React from "react";
import Slider from "react-slick";
import offer1 from '../../../assets/hotels/Frame 31 (1).png'
import offer2 from '../../../assets/hotels/Frame 31 (2).png'


export const Offers = ()=>{
   var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return <>
    <section className="container my-5 pb-5">
    <div className="py-3 ps-3">
          <h2 >Offers</h2>
        </div>
     <div className="slider-container">
      <Slider {...settings}>
        <div className="p-3">
         <div className="d-flex justify-content-center align-items-center rounded-4 shadow p-3">
         <div>
          <img src={offer1} alt="offer1"/>
         </div>
         <div className="px-4">
<h4>Enjoy up to 20% off on International Hotels</h4>
<p>Make the most of  this deal on your first booking with us.</p>
            <button className='btn bgPrimaryColor text-white rounded-5'>Book Now</button>
      </div>
         </div>
        </div>
        <div className="p-3">
         <div className="d-flex justify-content-center align-items-center rounded-4 shadow p-3">
         <div>
          <img src={offer1} alt="offer1"/>
         </div>
         <div className="px-4">
<h4>Enjoy up to 20% off on International Hotels</h4>
<p>Make the most of  this deal on your first booking with us.</p>
            <button className='btn bgPrimaryColor text-white rounded-5'>Book Now</button>
      </div>
         </div>
        </div>
        <div className="">
         <div className="d-flex justify-content-center align-items-center rounded-4 shadow p-4 m-3">
         <div>
          <img src={offer2} alt="offer1"/>
         </div>
         <div className="px-4">
<h4>Enjoy up to 20% off on International Hotels</h4>
<p>Make the most of  this deal on your first booking with us.</p>
            <button className='btn bgPrimaryColor text-white rounded-5'>Book Now</button>
      </div>
         </div>
        </div>
        <div className="">
         <div className="d-flex justify-content-center align-items-center rounded-4 shadow p-4 m-3">
         <div>
          <img src={offer1} alt="offer1"/>
         </div>
         <div className="px-4">
<h4>Enjoy up to 20% off on International Hotels</h4>
<p>Make the most of  this deal on your first booking with us.</p>
            <button className='btn bgPrimaryColor text-white rounded-5'>Book Now</button>
      </div>
         </div>
        </div>
      </Slider>
    </div>
    </section>
    
    </>

}