import React from "react";
import Slider from "react-slick";
import standardRoom from '../../../assets/sections/standerd-room/image 13.png'
function StandardRoomSlider() {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="">
          <img src={standardRoom} className="w-100" alt="" />
        </div>
        <div>
          <img src={standardRoom} alt="" />
        </div>
        <div>
          <img src={standardRoom} alt="" />
        </div>
        <div>
          <img src={standardRoom} alt="" />
        </div>
      </Slider>
    </div>
  );
}

export default StandardRoomSlider;
