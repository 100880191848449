import footerStyle from './footer.module.css'
export const Footer =()=>{
   return<>
   <section  className={` ${footerStyle.bgImage}`}>
  <div className='container py-5'>
   <div className='d-flex justify-content-center align-items-center'>
   <div className='col-md-4 px-3'>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
</div>
<div className='col-md-4 px-3'>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>

</div>
<div className='col-md-2 d-flex'>
   <div className=''>
<h6>Information</h6>
<ul className='list-group border-0 bg-transparent'>
<li className='list-group-item border-0  bg-transparent'>About Us</li>
<li className='list-group-item border-0  bg-transparent'>Rooms</li>
<li className='list-group-item border-0  bg-transparent'>Contact Us</li>
<li className='list-group-item border-0  bg-transparent'>Terms of Service</li>
</ul>
   </div>
</div>

   </div>
  </div>
   </section>
<div >


</div>
   </>
}