import React from "react"
import romanceStyle from './romanceRegency.module.css'
import bags from './../../../assets/Main/Group (1).png'
import bed from './../../../assets/Main/Vector.png'
import hotel from './../../../assets/Main/hotel.png'
import whyChooseUs from './../../../assets/hotels/illustrasi.png'
import romanceWaves from '../../..//assets/hotels/Frame 41 (6).png'
import { Categories } from '../../sections/categories/categories'
import { RecentlyViewed } from '../../sections/recentlyViewed/RecentlyViewed'
import { AllRooms } from '../../sections/allRooms/allRooms'
import {Offers } from '../../sections/offers/offers'

export const RomanceRegencyClub = ()=>{
   return<>
   {/* hero section */}
      <section className={`d-flex justify-content-center align-items-center ${romanceStyle.bgImage}`}>
         <div>
<div className=' col-md-6 mb-5'>
   <h1 className='BigshotOneFont'>Spend your vacation with our activities & Hotel</h1>
   <p>Welcome to our hotel, where luxury and relaxation await ,where hospitality meets comfort.</p>
</div>
         </div>
   </section>
   <section className={`d-flex justify-content-center align-items-center  ${romanceStyle.bgGif}`}>
   <img src={romanceWaves} className="w-100 bg-primary-color" alt="" />
   </section>
   {/* Why Choose Us */}
<section className="d-flex justify-content-center align-items-center py-5 my-5">
<div className="col-md-6 d-flex ">
   <img src={whyChooseUs} className="w-75" alt="" />
</div>
<div className="col-md-5 ">
   <div>
<h4>Why Choose Us</h4>
<p>"Welcome to our hotel, where hospitality meets comfort."</p>
   </div>
   <div className={`d-flex justify-content-center align-items-center p-3 cursor-pointer ${romanceStyle.item}`}>
   <div>
      <img src={bed} className="p-3" alt=""/>
   </div>
<div className="">
   <h6>Comfort</h6>
   Our hotel offers comfortable rooms and suites equipped with the latest amenities to ensure an unforgettable stay.
</div>
   </div>
   
   <div className={`d-flex justify-content-center align-items-center p-3 cursor-pointer ${romanceStyle.item}`}>
   <div>
      <img src={hotel} className="p-3" alt=""/>
   </div>
<div className="">
   <h6>Prime Location</h6>
   Our hotel is located in the heart of the city, providing easy access to major attractions and essential facilities.</div>
   </div>

   <div className={`d-flex justify-content-center align-items-center p-3 cursor-pointer ${romanceStyle.item}`}>
   <div>
      <img src={bags} className="p-3" alt=""/>
   </div>
<div className="">
   <h6>Excellent Service</h6>
   We offer exceptional customer service around the clock to meet all your needs and ensure your complete satisfaction.</div>
   </div>
</div>

</section>
<Categories/>
<RecentlyViewed/>
<AllRooms/>
<Offers/>
{/* <Reviews/> */}
   </>
}