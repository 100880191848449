import {Outlet}  from "react-router-dom"
import Navbar from "../navbar/navbar"
import { Footer } from "../footer/footer"

export const Layout = ()=>{
return <> 
<Navbar/>
<Outlet></Outlet>  
<Footer/>
</>
}