import React from "react";
import Slider from "react-slick";
import view1 from "../../../assets/recentely view/image 26.png";
import view2 from "../../../assets/recentely view/image 34.png";
import view3 from "../../../assets/recentely view/image 36.png";
import check from "../../../assets/recentely view/check 4.png";
import bed from "../../../assets/recentely view/cil_car-alt.png";
import person from "../../../assets/recentely view/f7_person-2.png";
import trekking from "../../../assets/recentely view/iconoir_trekking.png";
import television from "../../../assets/recentely view/mage_television.png";

import allRoomStyle from "../recentlyViewed/recentelyViewed.module.css";
import { Link } from "react-router-dom";

export const AllRooms = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: false, // Disable arrows
    // autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed to 2 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="container my-5">
        <div className="py-3">
          <h2>All Rooms</h2>
        </div>
        <div className="slider-container m-auto">
          <Slider {...settings}>
            <div className="py-4">
              <div class={`card me-4 rounded-4 shadow border my-3${allRoomStyle.cardPosition}`}>
                <img src={view3} class="card-img-top" alt="view1" />
                <div className={`${allRoomStyle.fav}`}>
                  <i
                    class={`fa-regular fa-heart ${allRoomStyle.fwLighter}`}
                  ></i>
                  {/* <i class="fa-solid fa-heart"></i> */}
                </div>
                <div class="card-body">
                  <div></div>
                  <div className="d-flex justify-content-between">
                    <h5 class="card-title">Gacuzzy suite</h5>
                    <div className="d-flex justify-content-center align-items-center">
                      {/* <i class="fa-regular fa-star"></i> */}
                      <i class="fa-solid fa-star"></i>
                      <h5 className="ms-2 mb-0">4.9</h5>
                    </div>
                  </div>
                  <span className="text-muted">3Days 4 Nights</span>
                  <div className="d-flex justify-content-between align-items-center py-3 text-muted">
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={person} alt="person" />
                      </div>
                      <span>2 person</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={television} alt="person" />
                      </div>
                      <span>Television</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={bed} alt="person" />
                      </div>
                      <span>King Size Bed</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={trekking} alt="person" />
                      </div>
                      <span>4 Activities</span>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">High Speed Wifi</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Shower</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Air-Conditioning</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Parking</p>
                    </div>
                    <Link className="text-dark">View More Amenities</Link>
                  </div>
                  <div className="d-flex py-4">
                  <p class="text-decoration-line-through pe-4 text-muted pt-1">₹100,952</p>
<h3 className="pe-2">₹88,952</h3>
<span className="text-muted pt-2">Per Person</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4">
              <div class={`card me-4 rounded-4 shadow border my-3${allRoomStyle.cardPosition}`}>
                <img src={view3} class="card-img-top" alt="view1" />
                <div className={`${allRoomStyle.fav}`}>
                  <i
                    class={`fa-regular fa-heart ${allRoomStyle.fwLighter}`}
                  ></i>
                  {/* <i class="fa-solid fa-heart"></i> */}
                </div>
                <div class="card-body">
                  <div></div>
                  <div className="d-flex justify-content-between">
                    <h5 class="card-title">Gacuzzy suite</h5>
                    <div className="d-flex justify-content-center align-items-center">
                      {/* <i class="fa-regular fa-star"></i> */}
                      <i class="fa-solid fa-star"></i>
                      <h5 className="ms-2 mb-0">4.9</h5>
                    </div>
                  </div>
                  <span className="text-muted">3Days 4 Nights</span>
                  <div className="d-flex justify-content-between align-items-center py-3 text-muted">
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={person} alt="person" />
                      </div>
                      <span>2 person</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={television} alt="person" />
                      </div>
                      <span>Television</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={bed} alt="person" />
                      </div>
                      <span>King Size Bed</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={trekking} alt="person" />
                      </div>
                      <span>4 Activities</span>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">High Speed Wifi</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Shower</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Air-Conditioning</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Parking</p>
                    </div>
                    <Link className="text-dark">View More Amenities</Link>
                  </div>
                  <div className="d-flex py-4">
                  <p class="text-decoration-line-through pe-4 text-muted pt-1">₹100,952</p>
<h3 className="pe-2">₹88,952</h3>
<span className="text-muted pt-2">Per Person</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4">
              <div class={`card me-4 rounded-4 shadow border my-3${allRoomStyle.cardPosition}`}>
                <img src={view3} class="card-img-top" alt="view1" />
                <div className={`${allRoomStyle.fav}`}>
                  <i
                    class={`fa-regular fa-heart ${allRoomStyle.fwLighter}`}
                  ></i>
                  {/* <i class="fa-solid fa-heart"></i> */}
                </div>
                <div class="card-body">
                  <div></div>
                  <div className="d-flex justify-content-between">
                    <h5 class="card-title">Gacuzzy suite</h5>
                    <div className="d-flex justify-content-center align-items-center">
                      {/* <i class="fa-regular fa-star"></i> */}
                      <i class="fa-solid fa-star"></i>
                      <h5 className="ms-2 mb-0">4.9</h5>
                    </div>
                  </div>
                  <span className="text-muted">3Days 4 Nights</span>
                  <div className="d-flex justify-content-between align-items-center py-3 text-muted">
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={person} alt="person" />
                      </div>
                      <span>2 person</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={television} alt="person" />
                      </div>
                      <span>Television</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={bed} alt="person" />
                      </div>
                      <span>King Size Bed</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={trekking} alt="person" />
                      </div>
                      <span>4 Activities</span>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">High Speed Wifi</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Shower</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Air-Conditioning</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Parking</p>
                    </div>
                    <Link className="text-dark">View More Amenities</Link>
                  </div>
                  <div className="d-flex py-4">
                  <p class="text-decoration-line-through pe-4 text-muted pt-1">₹100,952</p>
<h3 className="pe-2">₹88,952</h3>
<span className="text-muted pt-2">Per Person</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4">
              <div class={`card me-4 rounded-4 shadow border my-3${allRoomStyle.cardPosition}`}>
                <img src={view3} class="card-img-top" alt="view1" />
                <div className={`${allRoomStyle.fav}`}>
                  <i
                    class={`fa-regular fa-heart ${allRoomStyle.fwLighter}`}
                  ></i>
                  {/* <i class="fa-solid fa-heart"></i> */}
                </div>
                <div class="card-body">
                  <div></div>
                  <div className="d-flex justify-content-between">
                    <h5 class="card-title">Gacuzzy suite</h5>
                    <div className="d-flex justify-content-center align-items-center">
                      {/* <i class="fa-regular fa-star"></i> */}
                      <i class="fa-solid fa-star"></i>
                      <h5 className="ms-2 mb-0">4.9</h5>
                    </div>
                  </div>
                  <span className="text-muted">3Days 4 Nights</span>
                  <div className="d-flex justify-content-between align-items-center py-3 text-muted">
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={person} alt="person" />
                      </div>
                      <span>2 person</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={television} alt="person" />
                      </div>
                      <span>Television</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={bed} alt="person" />
                      </div>
                      <span>King Size Bed</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={trekking} alt="person" />
                      </div>
                      <span>4 Activities</span>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">High Speed Wifi</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Shower</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Air-Conditioning</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Parking</p>
                    </div>
                    <Link className="text-dark">View More Amenities</Link>
                  </div>
                  <div className="d-flex py-4">
                  <p class="text-decoration-line-through pe-4 text-muted pt-1">₹100,952</p>
<h3 className="pe-2">₹88,952</h3>
<span className="text-muted pt-2">Per Person</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4">
              <div class={`card me-4 rounded-4 shadow border my-3${allRoomStyle.cardPosition}`}>
                <img src={view3} class="card-img-top" alt="view1" />
                <div className={`${allRoomStyle.fav}`}>
                  <i
                    class={`fa-regular fa-heart ${allRoomStyle.fwLighter}`}
                  ></i>
                  {/* <i class="fa-solid fa-heart"></i> */}
                </div>
                <div class="card-body">
                  <div></div>
                  <div className="d-flex justify-content-between">
                    <h5 class="card-title">Gacuzzy suite</h5>
                    <div className="d-flex justify-content-center align-items-center">
                      {/* <i class="fa-regular fa-star"></i> */}
                      <i class="fa-solid fa-star"></i>
                      <h5 className="ms-2 mb-0">4.9</h5>
                    </div>
                  </div>
                  <span className="text-muted">3Days 4 Nights</span>
                  <div className="d-flex justify-content-between align-items-center py-3 text-muted">
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={person} alt="person" />
                      </div>
                      <span>2 person</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={television} alt="person" />
                      </div>
                      <span>Television</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={bed} alt="person" />
                      </div>
                      <span>King Size Bed</span>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={trekking} alt="person" />
                      </div>
                      <span>4 Activities</span>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">High Speed Wifi</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Shower</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Air-Conditioning</p>
                    </div>
                    <div className="d-flex">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Parking</p>
                    </div>
                    <Link className="text-dark">View More Amenities</Link>
                  </div>
                  <div className="d-flex py-4">
                  <p class="text-decoration-line-through pe-4 text-muted pt-1">₹100,952</p>
<h3 className="pe-2">₹88,952</h3>
<span className="text-muted pt-2">Per Person</span>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
};
