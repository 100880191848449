import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "@fortawesome/fontawesome-free/css/all.css";

import './App.css';
import './index.css'; 

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SignIn from './components/auth/signin/signin';
import SignUp from './components/auth/signup/signup';

import { Layout } from './components/layout/layout';
import Home from './components/home/home';
import { RoyalRegency } from './components/hotels/royalRegency/royalRegency';
import { PlazaAquaPark } from './components/hotels/plazaAquapark/plazaAquepark';
import { RomanceRegencyClub } from './components/hotels/romanceRegencyClub/romanceRegencyClub';
import About from './components/about/about';
import AccountFlow from './components/accountFlow/AccountFlow';

let routers = createBrowserRouter([
  {
    path: "", element: <Layout />, children: [
      { index: true, element: < Home/> },
      { path: "home", element: <Home /> },
      { path: "login", element: <SignIn /> },
      { path: "signup", element: <SignUp /> },
      { path: "royal-regency-hotel", element: <RoyalRegency /> },
      { path: "plaza-aqua-park", element: <PlazaAquaPark /> },
      { path: "romance-regency-club", element: <RomanceRegencyClub /> },
      { path: "about-us", element: <About /> },
      { path: "account", element: <AccountFlow /> },
    ],
  },
]);


function App() {
  return <>
    <RouterProvider router={routers}></RouterProvider>
    </>
}

export default App;
