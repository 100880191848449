import standardRoomStyle from './standerdRoom.module.css'
import persons from '../../../assets/Main/image 22.png'
import bed from '../../../assets/Main/image 23.png'
import space from '../../../assets/Main/image 24.png'
import window from '../../../assets/Main/image 25.png'
import check from '../../../assets/Main/check 1.png'
import { Link } from "react-router-dom"
import StandardRoomSlider from "./standerdRoomSlider"

export const StandardRoom = ()=>{

   return <>
     <section className="container my-5 border rounded-3">
      <div className=" row justify-content-center p-3">
      <div className="col-md-4 border-end ">
      <div className="item p-3">
         <div className="">
      <StandardRoomSlider/>     

         </div>
         <div className="row pt-3">
            <div className="col-md-6 d-flex">
            <div className="pe-3">
               <img src={persons} alt="" />
               </div>
               <div>
               <p>2 Person Accomodation</p>
               </div>
            </div>
            <div className="col-md-6 d-flex">
               <div className="pe-3">
               <img src={space} alt="" />
               </div>
               <div>
               <p>244 Sq. Ft</p>
               </div>
            </div>
            <div className="col-md-6 d-flex">
            <div className="pe-3">
               <img src={bed} alt="" />
               </div>
               <div>
               <p>King Size Bed</p>
               </div>
            </div>
            <div className="col-md-6 d-flex">
            <div className="pe-3">
               <img src={window} alt="" />
               </div>
               <div>
               <p>City View</p>
               </div>
            </div> 
         </div>
      </div>
   </div>
   <div className="col-md-4 border-end">
<div className="p-3">
<h2 className="pb-3">Standard Room</h2>
<p className="pb-3">Our spacious suites embody luxury and sophistication and enjoy the experience wit...   Read More</p>
<div className="pb-3">
                    <div className="d-flex mb-3">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">High Speed Wifi</p>
                    </div>
                    <div className="d-flex mb-3">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Shower</p>
                    </div>
                    <div className="d-flex mb-3">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Air-Conditioning</p>
                    </div>
                    <div className="d-flex mb-3">
                      <div className="pe-3">
                    <img src={check} alt="" srcset="" />
                      </div>
                     <p className="mb-0">Parking</p>
                    </div>
                  </div>
                    <Link className="text-dark text-decoration-none fw-semibold">View More Amenities</Link>



</div>
   </div>
   <div className="col-md-4">
<div className="p-3">
   <div className={`${standardRoomStyle.discountBg}`}>
      <h6 className="p-3 text-white">20% OFF</h6>
   </div>
   <div>
      <h5>Rs 4,000.00</h5>
      <p>Rs 5,000.00</p>
      <div className="pb-3">
      <h6>1 Room Per Night</h6>
      <h6>2 Adults, 0 Childern</h6>
      </div>
      <button className="btn bgPrimaryColor rounded-5 text-white mb-3">Add Room</button>
      <p className="text-danger">Only 1 Room Left. Hurry!</p>

   </div>
</div>
   </div>
      </div>
  
   </section>
   </>
}