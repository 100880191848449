import React, { useEffect, useState, useRef } from 'react';
import homeStyle from './home.module.css'
import animation from './animation.module.css'

import HomeSlider from '../homeSlider/home-slider'
import icon1 from "../../assets/home/facility 1.png";
import icon2 from "../../assets/home/facility 7.png";
import icon3 from "../../assets/home/facility 8.png";
import icon4 from "../../assets/home/facility 9.png";
import icon5 from "../../assets/home/facility 10.png";
import icon6 from "../../assets/home/facility 11.png";
import icon7 from "../../assets/home/facility 12.png";
import icon8 from "../../assets/home/facility 13.png";
import beach from "../../assets/home/Blob 1.png";
import room1 from "../../assets/home/Rectangle 10.png";
import room2 from "../../assets/home/Rectangle 10 (1).png";
import room3 from "../../assets/home/Rectangle 10 (2).png";
import video1 from "../../assets/videos/video1.mp4";
import welcome from "../../assets/home/image.png";
import gif from "../../assets/home/1.gif";
import Navbar from "../navbar/navbar";
import { Footer } from "../footer/footer";
import { useRoomsTypes } from '../../services/queries';

export default function Home() {

  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const hasUserInteracted = useRef(false);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current.play().catch((error) => {
              console.error("Failed to play the video:", error);
            });
          } else {
            videoRef.current.pause();
          }
        }
      });
    };

    const observerOptions = {
      threshold: 0.5, // Play/pause video when 50% of it is in view
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleUnmute = () => {
    if (videoRef.current) {
      setIsMuted(false);
      videoRef.current.muted = false;
      videoRef.current.play().catch((error) => {
        console.error("Failed to play the video:", error);
      });
    }
    if (isMuted==false) {
      setIsMuted(true);
    }
  };
  // const roomTypes = useRoomsTypes();
  // console.log(roomTypes.data);
  // if (roomTypes.isPending) {
  //   return <span>loading.............</span>
  // }
  // if (roomTypes.isError) {
  //   console.log(roomTypes.error);
  //   return <span>there is an error!</span>
  // }
  return (
    <>
      <HomeSlider />
      <section className={`${animation.animationStyle} p-5 mt-5`}>
      <div className="container">
<h1 className={`title ${animation.hFontStyle}`}>
    <span className={`${animation.span} ${animation.spanH1} h1`}>facilities</span>
    <span className={`${animation.span} ${animation.spanH1} h1`}>for your comfort</span>
  </h1>
</div>
      </section>
   {/* <p>{roomTypes.fetchStatus}</p> */}
   
    {/* {roomTypes.data.map((room,index)=>(
      <div key={index}>{room.nameEn}</div>
    ))} */}

      <section className="container my-5">
        {/* <div className="text-center py-5 ">
          <h2 className=" BigshotOneFont h1">facilities</h2>
          <p className="textMain">for your comfort</p>
        </div> */}
        <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 g-3 g-xl-5 align-items-center justify-content-center">
          <div className="col">
            <img className="w-100  rounded-5" src={icon1} alt="icon1" />
          </div>
          <div className="col">
            <img className="w-100" src={icon2} alt="icon1" />
          </div>
          <div className="col">
            <img className="w-100" src={icon3} alt="icon1" />
          </div>
          <div className="col">
            <img className="w-100" src={icon4} alt="icon1" />
          </div>
          <div className="col">
            <img className="w-100" src={icon5} alt="icon1" />
          </div>
          <div className="col">
            <img className="w-100" src={icon6} alt="icon1" />
          </div>
          <div className="col">
            <img className="w-100" src={icon7} alt="icon1" />
          </div>
          <div className="col">
            <img className="w-100" src={icon8} alt="icon1" />
          </div>
        </div>
      </section>
     
      <section className="container my-5 ">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-6">


        <h1 className={`BigshotOneFont ${homeStyle.headerStyle} lh-base`}>"Step into opulence, immerse in tailored comfort, depart enriched."</h1>
        <div>
                <p>sales@regencyplazasharm.net</p>
            </div>
        </div>
        <div className="col-md-6  order-first order-md-3">
        <div className={`${homeStyle.container1}`}>
  <div className={`${homeStyle.bordered}`}>
    <img className="" src={beach} />
  </div>
  <div className={`${animation.irrelativeMovement}`}>
      <div class={`${animation.box}`}>
  <div class={animation.shape}></div>
  <div class={animation.shape}></div>
  <div class={animation.shape}></div>
  <h1 class={animation.h1Animation}></h1>
</div>
      </div>

      <div className={animation.irrelativeMovementText}>
      <div class={`${animation.boxTitle}`}>
  <div class={animation.shape2}></div>
  <div class={animation.shape2}></div>
  <div class={animation.shape2}></div>
  <h1 class={animation.h1Animation}>
<span className={`font-monospace ${animation.fontAnimation}`}>that's the </span>
<h1 className={` ${animation.fontAnimation2}`}>Beach</h1>
    
  </h1>
</div>

      </div>
</div>
        </div>
      </div>

      </section>

      <section className="container-fluid mt-5 bg-lightMe">
  <div className="row mx-5 pb-2">
    <div className="col-md-4 d-flex justify-content-center align-items-center">
      <div>
        <div className="pb-2">
        <h1 className="BigshotOneFont font-monospace fw-bold lh-base">Get ready to be whisked away on an amazing tour of our hotel</h1>
        <div className='w-50 border-bottom border-dark'></div>
        </div>
        <div className='py-5'>
        <p className="font-monospace fw-bold">Embark on your journey with us! Explore our hotel's unique features and services through an interactive onboarding experience, designed to make your stay unforgettable from the moment you arrive.</p>
        </div>

      </div>
    </div>
    <div className="col-md-8 ">
    <div className={`vh-100  ${homeStyle.positionRelative}`}>
    <svg className={homeStyle.svgOverlay1} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160">
  <path fill="#f3f4f5" fillOpacity="1" d="M0,96L480,160L960,128L1440,160L1440,0L960,0L480,0L0,0Z"></path>
</svg>

<div ref={containerRef} className="w-100 h-100 position-relative">
      <video ref={videoRef} className="w-100 h-100" muted={isMuted} controls>
        <source src={video1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {isMuted && (
        <div
          onClick={handleUnmute}
          className="text-white position-absolute top-70 end-3 translate-middle"
        >
          <i className="fa-solid fa-volume-xmark cursor-pointer"></i>        

        </div>
      )}
            {!isMuted && (
        <div
          onClick={handleUnmute}
          className="text-white position-absolute top-70 end-3 translate-middle"
        >

          <i className="fa-solid fa-volume-high cursor-pointer"></i>
        </div>
      )}
    </div>
  <svg className={homeStyle.svgOverlay2} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fillOpacity="1" d="M0,64L480,128L960,64L1440,64L1440,320L960,320L480,320L0,320Z"></path></svg>
  </div>
      </div>
  </div>
</section>

      <section className={ `${homeStyle.bgHotelRoom} mb-5`}>
        <div className={ `p-5 text-center ${homeStyle.layer}`}>
            <div className='d-flex justify-content-center'>
              <div>
            <h2 className="text-uppercase border-bottom border-primary pb-2">Luxurious Rooms</h2>
            {/* <div className='border-bottom border-primary d-flex justify-content-center'></div> */}
            <p className='pt-3'>All rooms are design for your comfort</p>
              </div>
            </div>
        <div className="container">
        <div className="row align-items-center justify-content-center">
            <div className="col-md-6 col-xl-4">
                <div className="pt-5 pb-3 m-3 bg-white border rounded-3">
                    <img className="w-75 border rounded-3" src={room1} alt="room1" />
                    <p className="pt-3 px-5">Television set, Extra sheets, Breakfast, and fireplace</p>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
            <div className="pt-5 pb-3 m-3 bg-white border rounded-3">
                    <img className="w-75 border rounded-3" src={room2} alt="room1" />
                    <p className="pt-3 px-5">Television set, Extra sheets, Breakfast, and fireplace</p>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
            <div className="pt-5 pb-3 m-3 bg-white border rounded-3">
                    <img className="w-75 border rounded-3" src={room3} alt="room1" />
                    <p className="pt-3 px-5">Television set, Extra sheets, Breakfast, and fireplace</p>
                </div>
            </div>
        </div>
        </div>
        
        </div>
      </section>
      <section className="container pt-5 ">
        <div className={ `${homeStyle.bgSecondary} rounded-4 mb-5 row justify-content-between align-items-center `} >
        <div className="col-md-6 ps-0">
          <img  className={`text-center w-100`} src={welcome} alt="welcome" />
        </div>
        <div className="col-md-6 ">
<div className="item mx-5">
<h2 className="h1 BigshotOneFont ">Take a brief tour <br /> through our <br /> hotel's highlights</h2>
<p className="pe-5 me-5">"Prepare to embark on a virtual adventure through our hotel's offerings, where each click leads you closer to discovering the hidden gems and luxurious surprises awaiting your stay."</p>
</div>
</div>
        </div>

      </section>
    </>
  );
}
